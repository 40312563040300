import * as React from 'react'
import * as css from './Article.module.css'
import CategoryAndDate from './CategoryAndDate'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Article({ post }) {
	return (
		<article className={css.root}>
			<h1 className="h1">{post.title}</h1>

			<CategoryAndDate {...post} className="text-[14px]" />

			{post.featuredImage && (
				<GatsbyImage
					className="<md:fullwidth mb-8"
					image={getImage(post.featuredImage.asset)}
					alt={post.title}
				/>
			)}

			<SanityBlock className={css.body} body={post._rawBody} />

			<img
				className="mt-8 w-[30px]"
				src="/fastech-favicon.svg"
				alt=""
				draggable="false"
			/>
		</article>
	)
}
